import { useState, MouseEvent } from 'react';
import { upperCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { PATH_DASHBOARD, PATH_AUTH } from 'src/routes/paths';
import { useSelector } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import palette from 'src/theme/palette';
import MenuPopover from 'src/components/MenuPopover/MenuPopover';
import Avatar from 'src/components/Avatar';
import MenuPopoverButton from 'src/components/MenuPopover/MenuPopoverButton';

const MENU_OPTIONS = [
  {
    label: 'User Details',
    query: 'user-details',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Security',
    query: 'security',
    linkTo: PATH_DASHBOARD.user.profile,
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useSelector((state) => state.user);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const generateInitials = () => upperCase(currentUser?.name[0] + currentUser?.surname[0]);

  return (
    <>
      <MenuPopoverButton animateCaret={!!open} onClick={handleOpen}>
        <Avatar
          alt={currentUser?.name}
          src={currentUser?.avatar_url as string}
          sx={{ bgcolor: palette.light.background.neutral, mr: 1 }}
        >
          <Typography variant="subtitle2" noWrap>
            {generateInitials()}
          </Typography>
        </Avatar>
        <Typography fontWeight={700} variant="subtitle2" color={'text.primary'}>
          {currentUser?.name} {currentUser?.surname}
        </Typography>
      </MenuPopoverButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser?.name} {currentUser?.surname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{ mx: 1, borderColor: palette.dark.background.neutral }} />

        <Stack sx={{ py: 0.75 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              sx={{ py: 1, pl: 2.5, '&:hover': { borderRadius: 0 } }}
              key={option.label}
              to={`${option.linkTo}?tab=${option.query}`}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderColor: palette.dark.background.neutral }} />

        <MenuItem onClick={handleLogout} sx={{ pl: 2.5, my: 0.75, '&:hover': { borderRadius: 0 } }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

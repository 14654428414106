import { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../Iconify';

interface Props extends ButtonProps {
  animateCaret?: boolean;
}

const MenuPopoverButtonStyled = styled(Button)<Props>(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const MenuPopoverButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, sx, animateCaret, size, ...other }, ref) => {
    return (
      <MenuPopoverButtonStyled
        variant="text"
        ref={ref}
        sx={{
          ...sx,
        }}
        endIcon={
          <Iconify
            sx={{
              transform: animateCaret ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'all 0.1s ease-out',
              ml: 1.25,
            }}
            width={16}
            height={16}
            color={'text.secondary'}
            icon={'eva:arrow-ios-downward-fill'}
          />
        }
        {...other}
      >
        {children}
      </MenuPopoverButtonStyled>
    );
  }
);

export default MenuPopoverButton;

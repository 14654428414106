import axios from '../utils/axios';
import { DataSubmission, PostSubmission } from 'src/@types/Schemas/dataSubmission_schema';

export const getCurrentDataSubmission = async (queryParams = '') => {
  try {
    return await axios.get<DataSubmission>(`/data-submission${queryParams}`);
  } catch (err) {
    console.error(err);
  }
};

export const getAllDataSubmissions = async (queryParams = '') => {
  try {
    return await axios.get<DataSubmission[]>(`/data-submissions${queryParams}`);
  } catch (err) {
    console.error(err);
  }
};

export const postData = async (_submission_id: number, _file: File) => {
  try {
    const data = new FormData();
    data.append('submission_id', _submission_id?.toString());
    data.append('file', _file);

    const response = await axios.post<PostSubmission>(`/data-submission/file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteData = async (submission_id: number, fileId: number) => {
  try {
    return await axios.delete(`/data-submission/${submission_id}/file/${fileId}`);
  } catch (err) {
    console.error(err);
  }
};

export const submitData = async (submission_id: number) => {
  try {
    const response = await axios.post(`/data-submission/${submission_id}/submit`);
    return response?.data;
  } catch (err) {
    console.error(err);
  }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TUserSlice, IUser } from '../../@types/Schemas/user_schema';
import { dispatch } from '../store';
import { allUsers } from 'src/api/users_api';
import { allRoles } from 'src/api/role_api';
import { IEntity } from 'src/@types/Schemas/entity_schema';

// TODO
//  Update User Slice
//  comment: 1. Inappropriate Typescript usage + wrong types
//           2. Inappropriate usage of redux-toolkit slice

const initialState: TUserSlice = {
  isLoading: false,
  error: null,
  entities: [],
  currentUser: {
    user_id: '',
    name: '',
    surname: '',
    organisation_name: '',
    organisation_id: '',
    role: '',
    avatar_url: '',
    date_created: '',
    email: '',
    sub_entities: [],
  },
  users: [],
  roles: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE CURRENT USER
    updateCurrentUser(state, action: PayloadAction<IUser>) {
      state.currentUser = action.payload;
      state.isLoading = false;
      const { sub_entities, organisation_id, organisation_name } = action.payload;

      let entities: IEntity[] = [{ id: organisation_id, name: organisation_name }];

      if (sub_entities?.length) {
        entities = [...entities, ...sub_entities];
      }

      state.entities = entities;

      const [primaryEntity] = entities;
      state.selected_entity = primaryEntity;
    },

    // SELECT ORGANIZATION
    selectEntity(state, action: PayloadAction<IEntity>) {
      state.selected_entity = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
  },
});

export const { startLoading, hasError, updateCurrentUser, getUsersSuccess, selectEntity } =
  slice.actions;

export function getUsers(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParams = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response = await allUsers(queryParams);
      const status = response?.status;

      switch (status) {
        case 200:
          dispatch(slice?.actions?.getUsersSuccess(response?.data));
          break;
        default:
          console.error(response);
          dispatch(
            slice?.actions?.hasError('An error has occurred while trying to load the users')
          );
          break;
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError('An error has occurred while trying to load the users'));
    }
  };
}

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await allRoles();
      const status = response?.status;

      switch (status) {
        case 200:
          dispatch(slice?.actions?.getRolesSuccess(response?.data));
          break;
        default:
          console.error(response);
          dispatch(
            slice?.actions?.hasError('An error has occurred while trying to load the user roles')
          );
          break;
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.hasError('An error has occurred while trying to load the  user roles')
      );
    }
  };
}

// Reducer
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIntegration } from 'src/api/integrations/types';
import { IIntegrationsSlice } from './integrations.types';

const initialIntegrationsState: IIntegrationsSlice = {
  isLoading: false,
  isCredentialLoading: false,
  error: null,
  credentialError: null,
  integrations: { active: [], available: [] },
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: initialIntegrationsState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    setCredentialLoading(state, action: PayloadAction<boolean>) {
      state.isCredentialLoading = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
    },

    setCredentialError(state, action) {
      state.credentialError = action.payload;
    },

    setIntegrations(state, action: PayloadAction<IIntegration>) {
      state.integrations = action?.payload ? action.payload : { active: [], available: [] };
    },
  },
});

export const { setLoading, setError, setIntegrations, setCredentialLoading, setCredentialError } =
  integrationsSlice.actions;

export default integrationsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IJurisdictionsState } from './jurisdictions.types';
import { INexus, IRegistrations } from 'src/api/jurisdictions/types';

const initialJurisdictionsState: IJurisdictionsState = {
  isLoading: false,
  error: '',
  nexus: [],
  registrations: [],
};

export const jurisdictionsSlice = createSlice({
  name: 'jurisdictions',
  initialState: initialJurisdictionsState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      state.error = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setNexus: (state, action: PayloadAction<INexus[]>) => {
      state.nexus = action.payload;
    },
    setRegistrations: (state, action: PayloadAction<IRegistrations[]>) => {
      state.registrations = action.payload;
    },
  },
});

export const { setNexus, setRegistrations, setLoading, setError } = jurisdictionsSlice.actions;

export default jurisdictionsSlice.reducer;

import { FileRejection } from 'react-dropzone';
// @mui
import { Box, Alert } from '@mui/material';
// utils
import getFileData from '../../utils/getFileData';
import { useState } from 'react';

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[];
  onDismiss?: (files: FileRejection[]) => void;
};

export default function RejectionFiles({ fileRejections, onDismiss }: Props) {
  const [allErrors, setAllErrors] = useState(fileRejections);

  const errorMessages = {
    'file-too-large' : 'File is larger than the max size of 3.1MB'
  }

  const handleRemove = (error: FileRejection) => {
    const filteredItems = allErrors.filter((_error) => _error !== error);
    setAllErrors(filteredItems);
    if (onDismiss) onDismiss(filteredItems);
  };
  return (
    <Box mt={3} mb={2}>
      {allErrors.map(({ file, errors }, index) => {
        const { path } = getFileData(file);

        return (
          <Box mb={1} key={path}>
            <Alert severity="error" onClose={() => handleRemove(allErrors[index])}>
              {errors.map((error) => (
                <Box key={error.code} sx={{ typography: 'body2' }}>
                  Error - {error.code === 'file-too-large' ?  path + ' is larger than the max size of 3.1MB' : path + ' is not an allowed file format'}
                </Box> 
              ))}
            </Alert>
          </Box>
        );
      })}
    </Box>
  );
}

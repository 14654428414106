import axios from 'axios';
import { dispatch } from 'src/redux/store';
// config
import { HOST_API } from 'src/config';
import { setSession } from './jwt';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const accessDenied = error?.response?.status === 401;
    const isOTP = error?.request?.responseURL.includes('/token');
    const isSignIn = error?.request?.responseURL.includes('/sign-in');
    if (accessDenied && !isOTP && !isSignIn) {
      // TODO Not clearing the state after logout
      //  method not working
      dispatch({ type: 'LOGOUT' });
      setSession(null);
      window.location.replace('/');
    }
    Promise.reject((error.response && error.response.data) || 'Something went wrong');
    return error?.response;
  }
);

export default axiosInstance;

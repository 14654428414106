import axios from '../utils/axios';

export const createProfile = async (
  name: string | undefined,
  surname: string | undefined,
  email: string | undefined,
  organisation_id?: string | number
) =>
  axios.post(`/user`, {
    name,
    surname,
    email,
    organisation_id,
  });

export const updateProfile = async (
  name: string | undefined,
  surname: string | undefined,
  email: string | undefined,
  role: string | number | undefined,
  userId: number | string | null,
  organisation_id?: string | number
) =>
  axios.put(`/user/${userId}`, {
    name,
    surname,
    email,
    role_id: role,
    organisation_id,
  });

export const updatePassword = async (password: string) => axios.patch(`/password`, { password });

//TODO --> wrong typings
//  All APIs and Types should be refactored and be like Jurisdictions and Integrations
//  @see_more: /src/api/jurisdictions or /src/api/integrations
//  All Redux Slices should be refactored and be like Jurisdictions and Integrations
//  @see_more: /src/redux/jurisdictions or /src/redux/integrations
export const updateAvatar = async (userId: number, fd: any) =>
  axios.post(`/user/${userId}/avatar`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const allUsers = async (queryParams = '') => axios.get(`/users${queryParams}`);

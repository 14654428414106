import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#B9DBF8',
  light: '#62AFED',
  main: '#125EB5',
  dark: '#125EB5',
  darker: '#094196',
};
const SECONDARY = {
  lighter: '#B6DFD1',
  light: '#89CAB5',
  main: '#5FB598',
  dark: '#308867',
  darker: '#194C33',
};
const INFO = {
  lighter: '#BADDFF',
  light: '#5BB2FF',
  main: '#0583F2',
  dark: '#0583F2',
  darker: '#1240AD',
};
const SUCCESS = {
  lighter: '#B6DFD1',
  light: '#89CAB5',
  main: '#5FB598',
  dark: '#308867',
  darker: '#194C33',
};
const WARNING = {
  lighter: '#F9DFAF',
  light: '#F2B544',
  main: '#F2B544',
  dark: '#EB8A00',
  darker: '#D85100',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  50: '#FBFBFC',
  100: '#F3F3F3',
  150: '#F4F6F8',
  200: '#EBEBEB',
  300: '#DBDBDB',
  350: '#C1C6D5',
  400: '#B8B8B8',
  500: '#989898',
  600: '#707070',
  700: '#5C5C5C',
  800: '#3D3D3D',
  900: '#1D1D1D',
  500_8: alpha('#989898', 0.08),
  500_12: alpha('#989898', 0.12),
  500_16: alpha('#989898', 0.16),
  500_24: alpha('#989898', 0.24),
  500_32: alpha('#989898', 0.32),
  500_48: alpha('#989898', 0.48),
  500_56: alpha('#989898', 0.56),
  500_80: alpha('#989898', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#F7D2FF', '#D0AEFF', '#9E86FF', '#826AF9'],
  blue: ['#CCFAFF', '#A5F3FF', '#83CFFF', '#2D99FF'],
  green: ['#C0F2DC', '#A4F7CC', '#60F1C8', '#2CD9C5'],
  yellow: ['#FFF3D6', '#FFF7AE', '#FFEF5A', '#FFE700'],
  red: ['#FFF2D4', '#FFBD98', '#FF8F6D', '#FF6C40'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], blue: PRIMARY.darker },
    background: { paper: GREY[0], default: GREY[0], neutral: GREY[150] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;

import { useState, MouseEvent } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { useSelector, dispatch } from 'src/redux/store';
import MenuPopover from 'src/components/MenuPopover/MenuPopover';
import MenuPopoverButton from 'src/components/MenuPopover/MenuPopoverButton';
import { IEntity } from 'src/@types/Schemas/entity_schema';
import { selectEntity } from 'src/redux/slices/user';

export default function EntityPopover() {
  const { selected_entity, entities } = useSelector((state) => state.user);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (entity?: IEntity) => {
    if (entity) {
      dispatch(selectEntity(entity));
    }
    setOpen(null);
  };

  return (
    <Box>
      <MenuPopoverButton animateCaret={!!open} onClick={handleOpen}>
        <Typography variant="subtitle2" fontWeight={700} color={'text.primary'}>
          {selected_entity?.name}
        </Typography>
      </MenuPopoverButton>

      <MenuPopover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        arrow="top-left"
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleClose()}
        sx={{
          p: 0,
          width: '100%',
          mt: 1.5,
          maxWidth: 200,
          minWidth: 150,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            // borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ py: 1 }}>
          {!!entities?.length ? (
            entities.map((entity) => (
              <MenuItem
                selected={selected_entity?.id === entity.id}
                sx={{
                  py: 1.25,
                  pl: 2.5,
                }}
                key={entity.id}
                onClick={() => handleClose(entity)}
              >
                <Typography variant="body2" noWrap>
                  {entity.name}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <Typography
              sx={{ pl: 2.5, py: 1 }}
              variant="subtitle2"
              fontWeight={400}
              color={'text.primary'}
            >
              No Entities
            </Typography>
          )}
        </Box>
      </MenuPopover>
    </Box>
  );
}

import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { UploadDataSlice } from 'src/@types/uploadDataSlice';
import { getAllDataSubmissions, getCurrentDataSubmission } from 'src/api/dataSubmissions_api';

const initialState: UploadDataSlice = {
  isLoading: false,
  error: null,
  history: [],
  dataSubmissions: null,
};

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // FINISH LOADING
    finishLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },

    // GET DATA SUBMISSIONS
    getDataSubmissionsSuccess(state, action) {
      state.isLoading = false;
      state.dataSubmissions = action.payload;
      state.error = null;
    },

    // GET DATA HISTORY
    getDataHistorySuccess(state, action) {
      state.isLoading = false;
      state.history = action.payload;
      state.error = null;
    },
  },
});

export const { startLoading, finishLoading, hasError, getDataSubmissionsSuccess } = slice.actions;

export function getDataSubmission(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParams = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response = await getCurrentDataSubmission(queryParams);
      const status = response?.status;
      switch (status) {
        case 200:
          dispatch(slice?.actions?.getDataSubmissionsSuccess(response?.data));
          break;
        default:
          dispatch(
            slice?.actions?.hasError('An error has occurred while trying to load submission data')
          );
          break;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataHistory(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParams = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response = await getAllDataSubmissions(queryParams);
      const status = response?.status;
      switch (status) {
        case 200:
          dispatch(slice?.actions?.getDataHistorySuccess(response?.data));
          break;
        default:
          dispatch(slice?.actions?.hasError('Could not load submission history at this time'));
          break;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Reducer
export default slice.reducer;

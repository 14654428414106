import { createContext, ReactNode, useEffect, useMemo, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

// TODO
//  Update JWT Context
//  comment: clean up the code since it has hard readability
//  Also have some concerns that AuthProvider might work incorrect in some cases (edge)
//  Note: No need to validate token here. The accessToken set into the Axios instance header.
//        If token invalid then API will return 401 or 403. So this should be handled in Axios interceptors

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Verify = 'VERIFY_OTP',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Verify]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };
    case 'VERIFY_OTP':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const accessToken = useMemo(() => localStorage.getItem('accessToken'), []);

  if (localStorage.getItem('userObj') === 'undefined') localStorage.setItem('userObj', '');

  const userObj = localStorage.getItem('userObj');

  const user = userObj ? JSON.parse(userObj ?? '') : null;

  useEffect(() => {
    // TODO
    //  no sense to use async await here
    const initialize = async () => {
      try {
        // for now, we are not receiving correct form of jwt so we can't validate it
        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/sign-in', {
      email,
      password,
    });
    try {
      const { message } = response?.data;

      dispatch({
        type: Types.Login,
        payload: {
          user: message,
        },
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const verifyOtp = async (email: string, otp_code: string) => {
    try {
      const response = await axios.post('/token', {
        email,
        otp_code,
      });

      if (response?.data) {
        dispatch({
          type: Types.Verify,
          payload: {
            user: response?.data,
          },
        });
        setSession(response.data.token);
      }

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    try {
      const response = await axios.post('', {
        email,
        password,
        firstName,
        lastName,
      });
      const { accessToken, user } = response?.data;

      localStorage.setItem('accessToken', accessToken);

      dispatch({
        type: Types.Register,
        payload: {
          user,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    setSession(null);
    // TODO Not clearing the state after logout
    //  method not working
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        verifyOtp,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

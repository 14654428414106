import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilingSlice } from 'src/@types/filingSlice';
import { dispatch } from '../store';
import axios from 'src/utils/axios';
import { Filing, Filing_Month, FilingMonthly } from 'src/@types/Filing';

const initialState: FilingSlice = {
  isLoading: false,
  month: '',
  year: '',
  region: '',
  filings: [],
  filingsMonthly: [],
  error: null,
  filings_month: [],
  states: [],
  filings_region: [],
};

const slice = createSlice({
  name: 'filing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE MONTH
    updateMonth(state, action: PayloadAction<string>) {
      state.month = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    // UPDATE YEAR
    updateYear(state, action: PayloadAction<string>) {
      state.year = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    // UPDATE REGION
    updateRegion(state, action: PayloadAction<string>) {
      state.region = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    // GET FILINGS
    getFilingsSuccess(state, action) {
      state.isLoading = false;
      state.filings = action.payload;
      state.error = null;
    },

    // GET FILINGS MONTHLY
    getFilingsMonthlySuccess(state, action) {
      state.isLoading = false;
      state.filingsMonthly = action.payload;
      state.error = null;
    },

    // GET MONTH DETAIL
    getFilingMonthSuccess(state, action) {
      state.isLoading = false;
      state.filings_month = action.payload;
      state.error = null;
    },

    // GET STATES
    getStatesSuccess(state, action) {
      state.isLoading = false;
      state.states = action.payload;
      state.error = null;
    },
    // GET STATE DETAIL
    getStateDetailSuccess(state, action) {
      state.isLoading = false;
      state.filings_region = action.payload;
      state.error = null;
    },
  },
});

export const {
  startLoading,
  updateMonth,
  updateYear,
  updateRegion,
  hasError,
  getFilingsSuccess,
  getFilingsMonthlySuccess,
  getFilingMonthSuccess,
  getStatesSuccess,
} = slice.actions;

export function getFilings(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParam = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response: { data: { filings: Filing[] }; status: number } = await axios.get(
        `/filings${queryParam}`
      );
      const status = response?.status;
      switch (status) {
        case 200:
          dispatch(slice?.actions?.getFilingsSuccess(response?.data));
          break;
        default:
          console.error(response);
          dispatch(
            slice?.actions?.hasError(
              'An error has occured while trying to load the filings for this state'
            )
          );
          break;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthlyFilings(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParam = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response: {
        data: { filings: FilingMonthly[] };
        status: number;
      } = await axios.get(`/filings/monthly${queryParam}`);
      const status = response?.status;
      switch (status) {
        case 200:
          dispatch(slice?.actions?.getFilingsMonthlySuccess(response?.data));
          break;
        default:
          console.error(response);
          dispatch(
            slice?.actions?.hasError(
              'An error has occured while trying to load the monthly filings'
            )
          );
          break;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthDetails(_year: string, _month: string, organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParam = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response: { data: { monthData: Filing_Month[] } } = await axios.get(
        `/filings/${_year}/${_month}${queryParam}`
      );
      dispatch(slice.actions.getFilingMonthSuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStates(organisation_id?: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const queryParam = organisation_id ? `?organisation_id=${organisation_id}` : '';
      const response: { data: { states: string[] }; status: number } = await axios.get(
        `/states${queryParam}`
      );
      const status = response?.status;
      switch (status) {
        case 200:
          dispatch(slice?.actions?.getStatesSuccess(response?.data));
          break;
        default:
          console.error(response);
          dispatch(
            slice?.actions?.hasError(
              'An error has occured while trying to load the monthly filings'
            )
          );
          break;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
// Reducer

import { FileRejection, useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// type
import { UploadMultiFileProps } from './type';
//
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadMultiFile({
  error,
  setError,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });

  const [filteredFileRejections, setFilteredFileRejections] =
    useState<FileRejection[]>(fileRejections);

  useEffect(() => {
    if (setError) {
      setError(filteredFileRejections?.length > 0);
    }
  }, [setError, filteredFileRejections]);

  useEffect(() => {
    setFilteredFileRejections(fileRejections);
  }, [fileRejections]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />
        <BlockContent helperText={helperText} />
      </DropZoneStyle>

      {filteredFileRejections.length > 0 ? (
        <RejectionFiles
          fileRejections={filteredFileRejections}
          onDismiss={(files: FileRejection[]) => setFilteredFileRejections(files)}
        />
      ) : null}
      <MultiFilePreview files={files} showPreview={showPreview} onRemove={onRemove} />
    </Box>
  );
}

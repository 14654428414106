import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

const PrivacyRedirect: any = () => {
  window.location.replace('https://yondatax.com/privacy-policy/');
};

export default function Router() {
  return useRoutes([
    {
      path: '/password/*',
      element: <UpdatePassword />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'disabled', element: <LoginDisabled /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'update-password', element: <UpdatePassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'reset-success', element: <UpdateSuccess /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/app'} replace />, index: true },
        { path: 'app', element: <Navigate to="/jurisdictions" replace /> },
        {
          path: 'jurisdictions',
          children: [
            { element: <Navigate to="/jurisdictions/monitoring" replace />, index: true },
            { path: 'monitoring', element: <JurisdictionsMonitoring /> },
            { path: 'registered', element: <JurisdictionsRegistered /> },
            { path: 'registering', element: <JurisdictionsRegistering /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'profile', element: <UserProfile /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'upload',
          children: [
            { element: <Navigate to="/upload/new" replace />, index: true },
            { path: 'new', element: <UploadData /> },
            { path: 'history', element: <UploadDataHistory /> },
          ],
        },
        {
          path: 'filings',
          children: [
            { element: <Filings />, index: true },
            { path: 'summary', element: <Filings /> },
            { path: 'summary/month', element: <FilingsMonth /> },
            { path: 'region', element: <FilingsRegion /> },
            { path: 'region/state', element: <FillingState /> },
          ],
        },
        {
          path: 'integrations',
          children: [{ element: <Integrations />, index: true }],
        },
        {
          path: 'pay',
          children: [{ element: <Pay />, index: true }],
        },
      ],
    },

    { path: 'privacy', element: <PrivacyRedirect /> },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    // Other Routes
    {
      path: '/integration/ident',
      element: (
        <AuthGuard>
          <IntegrationActivated />
        </AuthGuard>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const UpdatePassword = Loadable(lazy(() => import('../pages/auth/UpdatePassword')));
const UpdateSuccess = Loadable(lazy(() => import('../pages/auth/UpdateSuccess')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const LoginDisabled = Loadable(lazy(() => import('../pages/auth/LoginDisabled')));

// DASHBOARD

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// UPLOAD DATA
const UploadData = Loadable(lazy(() => import('../pages/dashboard/UploadData')));
const UploadDataHistory = Loadable(lazy(() => import('../pages/dashboard/UploadDataHistory')));

// FILINGS
const Filings = Loadable(lazy(() => import('../pages/dashboard/Filings')));
const FilingsMonth = Loadable(lazy(() => import('../pages/dashboard/FilingsMonth')));
const FilingsRegion = Loadable(lazy(() => import('../pages/dashboard/FilingsRegion')));
const FillingState = Loadable(lazy(() => import('../pages/dashboard/FillingsState')));

// JURISDICTIONS
const JurisdictionsMonitoring = Loadable(
  lazy(() => import('../pages/dashboard/JurisdictionsMonitoring'))
);
const JurisdictionsRegistered = Loadable(
  lazy(() => import('../pages/dashboard/JurisdictionsRegistered'))
);
const JurisdictionsRegistering = Loadable(
  lazy(() => import('../pages/dashboard/JurisdictionsRegistering'))
);

// INTEGRATIONS
const Integrations = Loadable(lazy(() => import('../pages/dashboard/Integrations')));

// YONDA PAY
const Pay = Loadable(lazy(() => import('../pages/dashboard/Pay')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// OTHER
const IntegrationActivated = Loadable(lazy(() => import('../pages/IntegrationActivated')));
// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  updateSuccess: path(ROOTS_AUTH, '/reset-success'),
  loginDisabled: path(ROOTS_AUTH, '/disabled'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  upload: {
    root: path(ROOTS_DASHBOARD, '/upload'),
    new: path(ROOTS_DASHBOARD, '/upload/new'),
    history: path(ROOTS_DASHBOARD, '/upload/history'),
  },
  jurisdictions: {
    root: path(ROOTS_DASHBOARD, '/jurisdictions'),
    monitoring: path(ROOTS_DASHBOARD, '/jurisdictions/monitoring'),
    registering: path(ROOTS_DASHBOARD, '/jurisdictions/registering'),
    registered: path(ROOTS_DASHBOARD, '/jurisdictions/registered'),
  },
  filings: {
    root: path(ROOTS_DASHBOARD, '/filings'),
    dashboard: path(ROOTS_DASHBOARD, '/filings/summary'),
    month: path(ROOTS_DASHBOARD, '/filings/summary/month'),
    region: path(ROOTS_DASHBOARD, '/filings/region'),
    state: path(ROOTS_DASHBOARD, '/filings/region/state'),
  },
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations'),
  },
  pay: {
    root: path(ROOTS_DASHBOARD, '/pay'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

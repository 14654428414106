import { ReactNode, useEffect } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { useDispatch } from 'react-redux';
import { updateCurrentUser } from '../redux/slices/user';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const dispatchUser = useDispatch();

  useEffect(() => {
    const userObj = localStorage.getItem('userObj');
    const user = userObj ? JSON.parse(userObj ?? '') : null;

    if (user && isAuthenticated && isInitialized) {
      dispatchUser(updateCurrentUser(user));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }


  return <>{children}</>;
}

// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import SvgIconStyle from 'src/components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  invoice: getIcon('ic_invoice'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  page: getIcon('ic_page'),
  icUpload: getIcon('ic_upload'),
  userGroup: getIcon('ic_user_group'),
  settings: getIcon('ic_settings'),
  payments: getIcon('ic_banking'),
  map: getIcon('ic_location'),
};

const navConfig = [
  // MANAGEMENT
  {
    subheader: 'management',
    items: [
      {
        title: 'Jurisdictions',
        path: PATH_DASHBOARD.jurisdictions.root,
        icon: ICONS.map,
        // dropDownIsOpen: true,
      },
      {
        title: 'Filings',
        path: PATH_DASHBOARD.filings.region,
        icon: ICONS.invoice,
        // dropDownIsOpen: true,
        // commenting this out based on request
        // children: [
        //   { title: 'Monthly Summary', path: PATH_DASHBOARD.filings.dashboard },
        //   { title: 'State By State', path: PATH_DASHBOARD.filings.region },
        // ],
      },
      {
        title: 'Upload Data',
        path: PATH_DASHBOARD.upload.new,
        icon: ICONS.icUpload,
      },
      {
        title: 'Upload History',
        path: PATH_DASHBOARD.upload.history,
        icon: ICONS.page,
      },
      {
        title: 'My Profile',
        path: PATH_DASHBOARD.user.profile,
        icon: ICONS.user,
      },
      {
        title: 'User Management',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.userGroup,
        roleLock: 'Admin',
      },
      {
        title: 'Integrations',
        path: PATH_DASHBOARD.integrations.root,
        icon: ICONS.settings,
        roleLock: 'Admin',
      },
      // {
      //   title: 'Pay',
      //   path: PATH_DASHBOARD.pay.root,
      //   icon: ICONS.payments,
      //   roleLock: 'Admin',
      // },
    ],
  },
];

export default navConfig;

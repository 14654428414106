import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import filingReducer from './slices/filings';
import dataSubmissionsReducer from './slices/dataSubmissions';
import userReducer from './slices/user';

// new structure
import jurisdictionsReducer from './slices/jurisdictions/jurisdictions.slice';
import integrationsReducer from './slices/integrations/integrations.slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  filing: filingReducer,
  dataSubmission: dataSubmissionsReducer,
  user: userReducer,
  integrations: integrationsReducer,
  jurisdictions: jurisdictionsReducer,
});

export { rootPersistConfig, rootReducer };
